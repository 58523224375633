import { render, staticRenderFns } from "./InformationCardComponent.vue?vue&type=template&id=61d7e04d&scoped=true&"
import script from "./InformationCardComponent.vue?vue&type=script&lang=ts&"
export * from "./InformationCardComponent.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/InformationCardComponent.scss?vue&type=style&index=0&id=61d7e04d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61d7e04d",
  null
  
)

export default component.exports