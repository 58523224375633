







































import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FileResource from '@/models/graphql/FileResource';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';

@Component({
  components: { ButtonComponent },
})
export default class InformationCardComponent extends VueBaseWidget {
  WidgetTopGap: Record<string, string> = {
    NONE: '',
    QUARTER: 'mt-4',
    HALF: 'mt-40 mt-xl-5',
    FULL: 'mt-40 mt-md-80 mt-xl-6',
  };

  WidgetBottomGap: Record<string, string> = {
    NONE: '',
    QUARTER: 'mb-4',
    HALF: 'mb-40 mb-xl-5',
    FULL: 'mb-40 mb-md-80 mb-xl-6',
  };

  @Prop({ required: false, default: null })
  private readonly title!: string;

  @Prop({ required: false, default: null })
  private readonly subtitle!: string;

  @Prop({ required: false, default: null })
  private readonly btnTitle!: string;

  @Prop({ required: false, default: null })
  private readonly url!: string;

  @Prop({ required: false, default: null })
  private readonly image!: FileResource;

  private FileResourceHelper = FileResourceHelper;

  private onButtonClick(): void {
    if (this.url) {
      const localUrl = this.$t(this.url) as string;
      let external = localUrl.startsWith('//');
      if (external) {
        this.$logger.logMatomoStats(
          this.authUser,
          this.community.code || '',
          '',
          StatLoggerActions.CLICK,
          localUrl,
          -1,
          '',
          StatLoggerCategories.EVENT_INFORMATION,
          this.$i18n.locale,
        );
        window.open(localUrl, '_blank');
      } else {
        try {
          const url = new URL(localUrl);
          external = !!url.protocol;
          if (external) {
            this.$logger.logMatomoStats(
              this.authUser,
              this.community.code || '',
              '',
              StatLoggerActions.CLICK,
              localUrl,
              -1,
              '',
              StatLoggerCategories.EVENT_INFORMATION,
              this.$i18n.locale,
            );
            window.open(localUrl, '_blank');
          }
        } catch {
          let r = localUrl;
          const matches = localUrl.match(/(%[a-zA-Z-_]+%)/gs);
          if (matches) {
            matches.forEach((m) => {
              const prop = m.replaceAll('%', '').trim();
              if (this.$route.params[prop]) {
                r = r.replaceAll(m, this.$route.params[prop]);
              }
            });
          }
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code || '',
            '',
            StatLoggerActions.CLICK,
            r,
            -1,
            '',
            StatLoggerCategories.EVENT_INFORMATION,
            this.$i18n.locale,
          );
          this.$router.push(r);
        }
      }
    }
  }
}
